import React from 'react'
import { Input } from 'components/common/TextInput'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import Drawer from 'components/common/Drawer'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  Header,
  Wrapper,
  Title,
  Text,
  toolTipStyles,
  HeaderContainer,
  TitleContainer,
} from './styled-components'
import QuizList from './QuizList/QuizList'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import { Tooltip } from '@mui/material'
import { IQuizLayoutProps } from './quiz.interface'
import { roles } from 'utils/permission'

import PaginationV2 from 'components/common/PaginationV2'
import { SplashScreen } from 'components/common'
import useQuizLayout from 'hooks/quizzes/useQuizLayout'
import MyLoader from 'loading/loading'

const QuizLayout = ({
  type,
  id,
  routerState,
  companyCourse,
  isDraggable,
  gridStyle,
  IQTest,
  canCreate,
  fromQuestions,
  setOpenCreateQuizDrawer,
  hasCheckbox,
}: IQuizLayoutProps): React.JSX.Element => {
  const {
    state,
    handleSearchChange,
    t,
    setCurrentPage,
    searchText,
    selectItem,
    selectedItem,
    setFilterData,
    setFilterDrawer,
    setSearchText,
    companyId,
    filterData,
    filterDrawer,
    handlePaginationClick,
    quizList,
    currentPage,
    actions,
    onSearchSubmit,
    fromList,
    deleteQuizzes,
    currentUser,
    CreateAssessmentButton,
    createIqTestBtnDisabled,
    isSearchType,
    createButton,
    quizListLoading,
    isFiltered,
  } = useQuizLayout({
    canCreate,
    companyCourse,
    fromQuestions,
    id,
    IQTest,
    setOpenCreateQuizDrawer,
    type,
  })
  // for SUADA consultants it is necessary to select a company to use the quizzes page
  if (
    state.currentUser?.role === roles.consultant &&
    !state.selectedCompany?.id
  ) {
    return <SplashScreen subTitle={t('quizzes_layout.switch_company')} />
  }

  if (
    !isFiltered &&
    !searchText &&
    !quizList.data?.length &&
    !isSearchType &&
    !quizListLoading
  ) {
    const isIQTest = IQTest
    const showCreateAssessmentButton =
      fromList &&
      state?.userPermission?.createModule &&
      !createIqTestBtnDisabled

    return (
      <>
        <SplashScreen
          title={t(
            isIQTest
              ? 'quizzes_layout.no_assessments'
              : 'quizzes_layout.no_quizzes',
          )}
          subTitle={t(
            isIQTest
              ? 'quizzes_layout.create_assessments_information'
              : 'quizzes_layout.create_quizzes_information',
          )}
          createButton={showCreateAssessmentButton && createButton}
        />
      </>
    )
  }

  return (
    <div style={{ paddingTop: '20px' }}>
      <Header className={'withBorder'}>
        <Wrapper>
          <HeaderContainer>
            <TitleContainer>
              {!companyCourse && (
                <>
                  {fromList && state?.userPermission?.createModule ? (
                    createIqTestBtnDisabled ? (
                      <Tooltip
                        enterDelay={500}
                        title={
                          <span style={toolTipStyles}>
                            {t('general.disabled_assessment_button_tooltip')}
                          </span>
                        }
                        arrow
                      >
                        <div>
                          <CreateAssessmentButton />
                        </div>
                      </Tooltip>
                    ) : (
                      <CreateAssessmentButton />
                    )
                  ) : (
                    <Title>{t('quizzes_layout.title')}</Title>
                  )}

                  {!IQTest && state.userPermission.deleteQuiz && (
                    <GridMultipleActions
                      selectedItems={
                        selectedItem[currentPage]
                          ? selectedItem[currentPage].length
                          : 0
                      }
                      actions={
                        companyCourse && currentUser.companyId
                          ? []
                          : [
                              {
                                id: 0,
                                color: 'secondary',
                                tooltipText: `${t('actions.delete')}`,
                                disabled:
                                  !selectedItem[currentPage] ||
                                  selectedItem[currentPage].length < 1,
                                onClick: () =>
                                  deleteQuizzes(selectedItem[currentPage]),
                                component: <DeleteIcon fontSize="small" />,
                              },
                            ]
                      }
                    />
                  )}
                </>
              )}
            </TitleContainer>
            <Text>
              {IQTest
                ? t('quizzes_layout.iq_test_rule')
                : t('quizzes_layout.quiz_recommendation')}
            </Text>
          </HeaderContainer>

          <div style={{ display: 'flex', alignContent: 'center' }}>
            {!fromList && (
              <>
                <IconButton onClick={(): void => setFilterDrawer(true)}>
                  {isFiltered ? <RedFilterIcon /> : <FilterIcon />}
                </IconButton>
                {isFiltered && (
                  <IconButton
                    onClick={(): void => {
                      setFilterData({
                        filterOptions: null,
                        values: null,
                      })
                      setSearchText('')
                    }}
                  >
                    <FilterAltOffIcon />
                  </IconButton>
                )}
              </>
            )}

            {!fromList && (
              <div style={{ marginRight: '20px' }}>
                <form onSubmit={onSearchSubmit}>
                  <Input
                    label={t('general.search_placeholder')}
                    type="text"
                    size="small"
                    value={searchText}
                    onChange={handleSearchChange}
                    icon={
                      <IconButton type="submit">
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                  />
                </form>
              </div>
            )}
          </div>
        </Wrapper>
      </Header>

      {quizListLoading ? (
        <MyLoader />
      ) : (
        quizList && (
          <>
            {quizList.data.length === 0 && (searchText !== '' || isFiltered) ? (
              <SplashScreen
                title={t('general.no_results_found')}
                subTitle={t('general.Try_adjusting_your_search_or_filter')}
                textMarginTop="40px"
                isSearchOrFilter={true}
              />
            ) : (
              <>
                {quizList.data.length > 0 ? (
                  <>
                    <QuizList
                      IQTest={IQTest}
                      data={quizList.data || []}
                      actions={actions}
                      gridStyle={gridStyle || 'card'}
                      selectedItems={selectedItem[currentPage] || []}
                      selectItem={selectItem}
                      isDraggable={isDraggable ? true : false}
                      isOriginalCourse={companyCourse}
                      type={type}
                      routerState={routerState}
                      t={t}
                      hasCheckbox={hasCheckbox ?? true}
                    />
                    {quizList && quizList.totalPages > 1 && (
                      <div
                        style={{
                          marginTop: 20,
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <PaginationV2
                          currentPage={quizList ? quizList.currentPage : 1}
                          totalPages={quizList ? quizList.totalPages : 0}
                          handleChange={handlePaginationClick}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <MyLoader />
                )}
              </>
            )}
          </>
        )
      )}

      <Drawer
        opened={filterDrawer}
        toggleDrawer={(): void => undefined}
        totalWidth="700px"
      >
        <FilterDrawer
          onClose={(): void => setFilterDrawer(false)}
          setFilterData={setFilterData}
          filterData={filterData}
          companyId={companyId as string}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
    </div>
  )
}

export default QuizLayout
