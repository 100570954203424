import React, { ReactElement } from 'react'

import IconButton from '@mui/material/IconButton'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { Button } from 'components/common/Button'
import Drawer from 'components/common/Drawer'
import FilterCompanies from './FilterCompanies/FilterCompanies'

import CompanyList from './companyList'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import {
  PaginationContainer,
  RelativeContainer,
  ToolbarContainer,
  ToolbarItemWrapper,
  loaderStyles,
  toolbarStyle,
} from './styled-components'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'

import { Input } from 'components/common/TextInput'
import MyLoader from 'loading/loading'
import useCompanyLayout from 'hooks/companies/useCompanyLayout'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { Tooltip } from '@mui/material'
import PaginationV2 from 'components/common/PaginationV2'

const CompaniesLayout = (): ReactElement => {
  const {
    t,
    selectedItem,
    toolbarAction,
    handleResetClick,
    handleFiltersClick,
    handleSearchSubmit,
    searchValue,
    handleSearchChange,
    loading,
    deleteCompanyLoading,
    companies,
    selectItem,
    actions,
    handlePaginationClick,
    filterDrawerOpened,
    filter,
    handleCloseFilterDrawer,
    setCurrentPage,
    breadCrumbData,
    handleOpenAddCompanyDrawer,
    isFiltered,
    filterOptions,
    setFilterOptions,
  } = useCompanyLayout()

  return (
    <div>
      <ToolbarItem>
        <Breadcrumb data={breadCrumbData} />
      </ToolbarItem>
      <Toolbar styles={toolbarStyle}>
        <ToolbarItemWrapper>
          <ToolbarContainer>
            <ToolbarItem>
              <Button
                text={t('companies_layout.create_company')}
                color="secondary"
                onClick={(): void => handleOpenAddCompanyDrawer()}
                icon={<AddCircleRoundedIcon />}
                background="#06C68F"
              />
            </ToolbarItem>

            <ToolbarItem>
              <GridMultipleActions
                selectedItems={selectedItem.length}
                actions={toolbarAction}
              />
            </ToolbarItem>
          </ToolbarContainer>

          <div className="wrapper">
            <ToolbarItem $noGap>
              <IconButton onClick={handleFiltersClick}>
                <Tooltip title={t('actions.filter')}>
                  {isFiltered ? <RedFilterIcon /> : <FilterIcon />}
                </Tooltip>
              </IconButton>
              {isFiltered && (
                <IconButton onClick={handleResetClick}>
                  <FilterAltOffIcon />
                </IconButton>
              )}
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={handleSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchValue}
                  onChange={handleSearchChange}
                  $iconInStart
                  icon={
                    <IconButton onClick={handleSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>

      <RelativeContainer>
        {(loading || deleteCompanyLoading) && !companies && (
          <MyLoader width={350} speed={0.8} style={loaderStyles} />
        )}
        {companies && (
          <>
            <CompanyList
              data={companies.data}
              selectedItem={selectedItem}
              selectItem={selectItem}
              actions={actions}
            />
            {companies.totalPages > 1 && (
              <PaginationContainer>
                <PaginationV2
                  currentPage={companies.currentPage || 1}
                  totalPages={companies.totalPages || 0}
                  handleChange={handlePaginationClick}
                />
              </PaginationContainer>
            )}
          </>
        )}
      </RelativeContainer>

      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={(): void => undefined}
        totalWidth="600px"
      >
        <FilterCompanies
          filterOptions={filterOptions}
          filter={filter}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          onClose={handleCloseFilterDrawer}
          setCurrentPage={setCurrentPage}
        />
      </Drawer>
    </div>
  )
}

export default CompaniesLayout
