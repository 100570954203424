import React, {
  useState,
  useEffect,
  memo,
  useCallback,
  ChangeEvent,
  ReactElement,
  FormEvent,
} from 'react'
import _, { debounce } from 'lodash'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { useFetchGroups } from 'hooks/group/useGroups'

import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'

import Grid from 'components/common/GridV2'
import Drawer from 'components/common/Drawer'
import { Button } from 'components/common/Button'

import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import AddGroupDrawer from './AddGroupDrawer/AddGroupDrawer'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { useUserValue } from 'context/UserContext'

import { columnConfig, actionConfig, studentActionConfig } from './gridConfigV2'
import useDeleteGroup from 'hooks/group/useDeleteGroup'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import { ReactComponent as DownloadIcon } from 'assets/download.svg'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import PaginationV2 from 'components/common/PaginationV2'
import { useNavigate } from 'react-router-dom'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { DrawerEventEmitter } from 'helpers/drawer'
import ImportGroupsDrawer from './ImportGroups/ImportGroupsDrawer'
import ImportGroupsToTestsDrawer from './ImportGroupsToTests/ImportGroupsToTestsDrawer'
import GridActionMenu from 'components/common/GridV2/GridActionMenu/GridActionMenu'
import ImportStudentsInGroupsDrawer from './ImportStudentsInGroups/ImportStudentsInGroupsDrawer'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { ReactComponent as Users } from 'components/common/Button/icons/user-multiple.svg'
import {
  FlexComponent,
  LayoutWrapper,
  StyledPagination,
} from './styled-components'
import AddCircleIcon from '@mui/icons-material/Add'
import TextInput from 'components/common/TextInput/TextInput'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import { GridLayoutActions } from 'components/common/Grid/styled-components'
import { Action } from 'components/UsersLayout/styled-components'
import Tooltip from '@mui/material/Tooltip'
import { IGroupLayoutProps } from './groups.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { SplashScreen } from 'components/common'
import { StudentsActionsContainer } from 'components/common/GridV2/styled-components'
import { IUserCompany } from 'interfaces/users'
import {
  IFilterQueryType,
  ISelectedAll,
} from 'components/common/FilterInterface/filter.interface'
import { IGroup } from 'interfaces/groups'
import MyLoader from 'loading/loading'
import { IGroupFilterDrawerOptions } from './FilterDrawer/filterDrawer.interface'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

const GroupLayout: React.FC<IGroupLayoutProps> = ({
  showBreadcrumb = true,
  courseId,
}) => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [drawerOpened, setDrawerOpened] = useState({
    add: false,
    importGroups: false,
    importStudentsInGroups: false,
    importGroupsToTests: false,
  })
  const [selectedItem, setSelectedItem] = useState<{
    [key: number]: string[]
  }>({})
  const [selectAll, setSelectAll] = useState<ISelectedAll>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [formData, setFormData] = useState<string>('')
  const [isFiltered, setIsFiltered] = useState(false)
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  const [defaultFilter, setDefaultFilter] = useState<IFilterQueryType | null>(
    null,
  )
  const [filterDrawer, setFilterDrawer] = useState<IGroupFilterDrawerOptions>({
    filterOptions: null,
    filterValues: null,
    opened: false,
  })

  const userCompany = state?.currentUser?.companyId as IUserCompany
  const companyId = userCompany?.id || state?.selectedCompany?.id || null

  useEffect(() => {
    let deffilter: IFilterQueryType = companyId
      ? {
          company: {
            type: LowerCaseFilterableFieldType.EXACT,
            value: companyId,
          },
        }
      : {}

    if (courseId) {
      deffilter = {
        ...deffilter,
        courses: {
          nestedField: 'courseId',
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: courseId,
        },
      }
    }

    setFilterDrawer({
      filterOptions: deffilter,
      filterValues: null,
      opened: false,
    })
    setDefaultFilter(deffilter)
  }, [state, courseId])

  const [searchText, setSearchText] = useState('')

  const { groups, loading, error, refetch } = useFetchGroups(
    currentPage,
    perPage,
    filterDrawer.filterOptions || {},
  )
  const buttonActions = studentActionConfig((field: string) => {
    setDrawerOpened({
      ...drawerOpened,
      [field]: true,
    })
  }, t)

  if (error) console.log('useFetchGroupsError', error)
  const { exportData } = useExportDataService()

  const { deleteGroupServ } = useDeleteGroup()

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = groups?.data.map((n) => n.id) as string[]
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handleDeleteGroup = (id?: string): void => {
    let ids: string[]
    if (id) ids = [id]
    else {
      const mappedSelectedIds = Object.keys(selectedItem).length
        ? Object.values(selectedItem).reduce(
            (acc: string[], curr: string[]) => [...acc, ...curr],
            [],
          )
        : []
      ids = mappedSelectedIds
    }

    if (ids.length <= 0) return

    const params = {
      title:
        ids.length > 1
          ? `${t('popups.delete_many')} ${t('general.groups')}`
          : `${t('popups.delete_single')} ${t('general.group')}`,
      onConfirm: (): void => {
        deleteGroupServ(ids, refetch)
        setSelectedItem([])
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleClick = (e: IGroup | string, field?: string): void => {
    if (field === 'delete') {
      handleDeleteGroup(e as string)
      return
    }

    const group = e as IGroup
    if (field === 'info') {
      DrawerEventEmitter.emit('openDrawer', 'groupInformation', true, {
        id: group.id,
        companyId: group.company ? group.company.id : null,
      })
    } else if (field === 'addStudentInGroup') {
      DrawerEventEmitter.emit('openDrawer', 'addStudentInGroup', true, {
        groupData: e,
      })
    } else navigate(`/group/${group.id}`)
  }

  const closeDrawer = (): void =>
    setDrawerOpened({
      add: false,
      importGroups: false,
      importStudentsInGroups: false,
      importGroupsToTests: false,
    })

  const handleExportData = (): void => {
    const mappedSelectedIds = Object.keys(selectedItem).length
      ? Object.values(selectedItem).reduce(
          (acc: string[], curr: string[]) => [...acc, ...curr],
          [] as string[],
        )
      : []
    if (mappedSelectedIds.length > 0) {
      exportData('group', mappedSelectedIds, null, (link: string) => {
        window.open(link, '_blank')
      })
    }
  }

  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      const filterOptions = {
        ...filterDrawer.filterOptions,
        name: { type: LowerCaseFilterableFieldType.MATCH, value: searchTerm },
      }
      refetch({
        filter: filterOptions,
        currentPage: 1,
        perPage,
      })
      setCurrentPage(1)
      setFilterDrawer({ ...filterDrawer, filterOptions })
      setIsSearchType(searchTerm !== '')
    }, 500),
    [filterDrawer.filterOptions, perPage, refetch],
  )

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const filterOptions = {
      ...filterDrawer.filterOptions,
      name: { type: 'match', value: searchText },
    }
    refetch({
      filter: {
        ...filterOptions,
      },
      currentPage: 1,
      perPage,
    })
    setCurrentPage(1)
    setFilterDrawer({ ...filterDrawer, filterOptions })
    setSelectedItem({})
    setSelectAll({})
    setIsSearchType(true)
  }

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget
      setSearchText(value)
      debouncedSearch(value)
      setIsSearchType(true)
    },
    [debouncedSearch],
  )

  const handlePaginationClick = (
    _: ChangeEvent<unknown> | null,
    newPage: number,
  ): void => {
    refetch({
      filter: filterDrawer.filterOptions || {},
      currentPage: newPage,
      perPage,
    })
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    refetch({
      filter: {},
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  useEffect(() => {
    if (formData) {
      const isEmpty = _.values(formData).every(_.isEmpty)
      if (!isEmpty) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            closeDrawer()
            setFormData('')
          },
          onClose: () => {
            setFormData('')
          },
          confirmText: 'Yes, Cancel!',
          cancelText: 'No',
        })
      }

      if (isEmpty) {
        closeDrawer()
        setFormData('')
      }
    }
  }, [formData])

  const config = columnConfig(handleClick, selectItem, t, true)
  const actions = actionConfig(handleClick, state.userPermission, t)

  const handleButtonClick = (): void => {
    setDrawerOpened({ ...drawerOpened, add: true })
  }

  const breadCrumbData = [
    {
      label: 'Groups',
      icon: <Users />,
    },
  ]

  const createButton = (): ReactElement => (
    <>
      <Button
        text={t('groups_layout.create_group')}
        color="secondary"
        onClick={handleButtonClick}
        icon={<AddCircleIcon />}
        background="#06C68F"
      />
    </>
  )

  const resetFilter = (): void => {
    setSearchText('')
    setFilterDrawer({
      filterOptions: defaultFilter,
      filterValues: null,
      opened: false,
    })
    setSelectedItem({})
    setSelectAll({})
    setIsFiltered(false)
    setIsSearchType(false)
    refetch({
      filter: defaultFilter || {},
      currentPage: 1,
      perPage,
    })
    setCurrentPage(1)
  }

  return (
    <>
      {showBreadcrumb && <Breadcrumb data={breadCrumbData} />}
      <>
        {!loading &&
        groups &&
        companyId &&
        searchText === '' &&
        !isSearchType &&
        !isFiltered &&
        groups?.data.length === 0 ? (
          <SplashScreen
            title={t('groups_layout.no_groups')}
            subTitle={t('groups_layout.create_groups')}
            createButton={createButton}
          />
        ) : (
          <LayoutWrapper>
            <Toolbar styles={{ justifyContent: 'space-between' }}>
              <GridLayoutActions>
                <div>
                  <Button
                    text={t('groups_layout.create_group')}
                    color="secondary"
                    onClick={handleButtonClick}
                    icon={<AddCircleIcon />}
                    background="#06C68F"
                  />
                </div>
                <GridMultipleActions
                  selectedItems={
                    selectedItem[currentPage]
                      ? selectedItem[currentPage].length
                      : 0
                  }
                  actions={[
                    {
                      id: 0,
                      color: 'secondary',
                      // tooltipText: `${t('actions.delete')}`,
                      hide: !state.userPermission.deleteGroup,
                      disabled: selectedItem[currentPage]
                        ? selectedItem[currentPage].length < 1
                        : true,
                      onClick: () => handleDeleteGroup(),
                      component: (
                        <Action $hoverColor="#EA382A">
                          <Tooltip title={t('actions.delete')} arrow>
                            <DeleteIcon />
                          </Tooltip>
                        </Action>
                      ),
                    },
                    {
                      id: 1,
                      color: 'secondary',
                      // tooltipText: `${t('actions.export')}`,
                      hide: !state.userPermission.deleteGroup,
                      disabled: selectedItem[currentPage]
                        ? selectedItem[currentPage].length < 1
                        : true,
                      onClick: () => handleExportData(),
                      component: (
                        <Action $hoverColor="#3892F4">
                          <Tooltip title={t('actions.download')} arrow>
                            <DownloadIcon />
                          </Tooltip>
                        </Action>
                      ),
                    },
                  ].filter((i) => !i.hide)}
                />
              </GridLayoutActions>

              <FlexComponent>
                <ToolbarItem>
                  {state?.userPermission?.createGroup && (
                    <GridActionMenu
                      actionConfig={buttonActions}
                      row={{}}
                      icon={
                        <Button
                          text={t('actions.import')}
                          size="small"
                          color="secondary"
                          icon={<ArrowDropDownIcon />}
                          iconPosition="right"
                          disableElevation
                          // disableRipple
                          background="#06C68F"
                        />
                      }
                    />
                  )}
                  <ToolbarItem $noGap>
                    <IconButton
                      onClick={(): void =>
                        setFilterDrawer({ ...filterDrawer, opened: true })
                      }
                    >
                      {isFiltered ? (
                        <RedFilterIcon color="#06C68F" />
                      ) : (
                        <FilterIcon />
                      )}
                    </IconButton>
                    {isFiltered && (
                      <IconButton onClick={resetFilter}>
                        <FilterAltOffIcon />
                      </IconButton>
                    )}
                  </ToolbarItem>

                  <form onSubmit={handleSearchSubmit}>
                    <TextInput
                      label={t('general.search_placeholder')}
                      type="text"
                      size="small"
                      value={searchText}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type="submit">
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                      $iconInStart
                    />
                  </form>
                </ToolbarItem>
              </FlexComponent>
            </Toolbar>

            {loading ? (
              <MyLoader />
            ) : (
              groups && (
                <>
                  {groups.data.length === 0 &&
                  (searchText !== '' || isFiltered) ? (
                    <SplashScreen
                      title={t('general.no_results_found')}
                      subTitle={t(
                        'general.Try_adjusting_your_search_or_filter',
                      )}
                      textMarginTop="40px"
                      isSearchOrFilter={true}
                    />
                  ) : (
                    <>
                      {groups.data.length > 0 ? (
                        <>
                          <Grid
                            data={groups.data}
                            config={config}
                            redirectToDetailsHandler={handleClick}
                            actionConfig={actions}
                            actionTilesLength={3}
                            selectItem={selectItem}
                            selectAllItem={selectAllItem}
                            selected={_.size(selectedItem[currentPage] || [])}
                            selectedItems={selectedItem[currentPage] || []}
                            resetVisible={
                              !!searchText || !!filterDrawer?.filterValues
                            }
                            ActionsContainer={StudentsActionsContainer}
                          />
                          <StyledPagination>
                            <PerPageDropdown
                              value={perPage}
                              onChange={(value): void =>
                                handleChangeRowsPerPage({
                                  target: {
                                    value: value.toString(),
                                  } as HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                              options={[10, 20, 50]}
                            />
                            <PaginationV2
                              currentPage={currentPage}
                              totalPages={
                                groups.totalCount
                                  ? Math.ceil(groups.totalCount / perPage)
                                  : 1
                              }
                              handleChange={handlePaginationClick}
                            />
                          </StyledPagination>
                        </>
                      ) : (
                        <MyLoader />
                      )}
                    </>
                  )}
                </>
              )
            )}

            <Drawer
              opened={filterDrawer.opened}
              toggleDrawer={(): void => undefined}
              totalWidth="600px"
            >
              <FilterDrawer
                closeDrawer={(): void =>
                  setFilterDrawer({ ...filterDrawer, opened: false })
                }
                setIsFiltered={setIsFiltered}
                setFilterOptions={setFilterDrawer}
                filterOptions={filterDrawer}
                refetchGroup={refetch}
                setCurrentPage={setCurrentPage}
                defaultFilter={defaultFilter as IFilterQueryType}
                companyId={(state?.currentUser?.companyId as IUserCompany)?.id}
                useCompanyId={
                  !!state?.currentUser?.companyId &&
                  state.currentUser.companyId !== null
                }
              />
            </Drawer>
            <Drawer
              opened={drawerOpened.importStudentsInGroups}
              toggleDrawer={(): void => undefined}
              totalWidth="900px"
            >
              <ImportStudentsInGroupsDrawer
                onClose={closeDrawer}
                refetch={refetch}
              />
            </Drawer>
            <Drawer
              opened={drawerOpened.importGroups}
              toggleDrawer={(): void => undefined}
              totalWidth="900px"
            >
              <ImportGroupsDrawer onClose={closeDrawer} refetch={refetch} />
            </Drawer>
            <Drawer
              opened={drawerOpened.importGroupsToTests}
              toggleDrawer={(): void => undefined}
              totalWidth="900px"
            >
              <ImportGroupsToTestsDrawer
                onClose={closeDrawer}
                refetch={refetch}
              />
            </Drawer>
          </LayoutWrapper>
        )}
        <Drawer opened={drawerOpened.add} totalWidth="750px">
          <AddGroupDrawer
            companyId={companyId as string}
            onClose={(): void => closeDrawer()}
          />
        </Drawer>
      </>
    </>
  )
}

export default memo(GroupLayout)
