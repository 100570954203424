import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Checkbox from '@mui/material/Checkbox'
import { TFunction } from 'interfaces/TFunction'
import {
  Wrapper,
  BoolIcon,
  AnswersWrapper,
  AnswerContainer,
  CorrectAnswerTitle,
  BorderStyle,
} from './styled-components'

interface Answer {
  isCorrect: boolean
  value: string
}

export enum QuestionBoolAnswer {
  TRUE = 'true',
  FALSE = 'false',
}

interface Params {
  type: string
  answers?: Answer[]
  boolAnswer?: QuestionBoolAnswer
  t?: TFunction<'translation', undefined>
}

const QuestionAnswers = ({
  type,
  answers,
  boolAnswer,
}: Params): React.JSX.Element => {
  const boolean = boolAnswer === QuestionBoolAnswer.TRUE
  const renderBool = (): React.JSX.Element => (
    <Wrapper>
      <BoolIcon className="true"></BoolIcon>
      <BoolIcon className="false"></BoolIcon>
    </Wrapper>
  )

  const renderSingle = (
    title?: string,
    correctMode?: boolean,
  ): React.JSX.Element => (
    <>
      {correctMode && <CorrectAnswerTitle>{title}</CorrectAnswerTitle>}
      <AnswersWrapper>
        {answers &&
          !correctMode &&
          answers.map((answer: Answer) => (
            <BorderStyle isCorrect={correctMode && answer.isCorrect}>
              <FormControlLabel
                value={answer.value}
                key={answer.value}
                control={
                  <Radio
                    disabled
                    checked={correctMode ? answer.isCorrect : false}
                  />
                }
                label={answer.value}
              />
            </BorderStyle>
          ))}

        {answers &&
          correctMode &&
          answers.map((answer: Answer) => {
            return (
              answer.isCorrect && (
                <BorderStyle isCorrect={correctMode && answer.isCorrect}>
                  <FormControlLabel
                    key={answer.value}
                    value={answer.value}
                    control={
                      <Radio
                        disabled
                        checked={correctMode ? answer.isCorrect : false}
                      />
                    }
                    label={answer.value}
                  />
                </BorderStyle>
              )
            )
          })}
      </AnswersWrapper>
    </>
  )

  const renderMultiple = (
    title?: string,
    correctMode?: boolean,
  ): React.JSX.Element => (
    <>
      {correctMode && <CorrectAnswerTitle>{title}</CorrectAnswerTitle>}
      <AnswersWrapper>
        {answers &&
          answers.map((answer: Answer) => (
            <BorderStyle isCorrect={correctMode && answer.isCorrect}>
              <FormControlLabel
                value={answer.value}
                control={
                  <Checkbox
                    disabled
                    checked={correctMode ? answer.isCorrect : false}
                  />
                }
                label={answer.value}
                key={answer.value}
              />
            </BorderStyle>
          ))}
      </AnswersWrapper>
    </>
  )

  return (
    <>
      {type === 'boolean' && renderBool()}
      {type === 'single' && renderSingle()}
      {type === 'multiple' && renderMultiple()}
      <AnswerContainer>
        {type === 'boolean' && (
          <>
            <CorrectAnswerTitle>
              <BorderStyle isCorrect={boolean}>
                <FormControlLabel
                  control={<Radio disabled checked={boolean} />}
                  label={<span>True</span>}
                />
              </BorderStyle>
              <BorderStyle isCorrect={!boolean}>
                <FormControlLabel
                  control={<Radio disabled checked={!boolean} />}
                  label={<span>False</span>}
                />
              </BorderStyle>
            </CorrectAnswerTitle>
          </>
        )}

        {type === 'single' && renderSingle(``, true)}
        {type === 'multiple' && renderMultiple(``, true)}
      </AnswerContainer>
    </>
  )
}

export default QuestionAnswers
