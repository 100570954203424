import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/common/Button'
import AddCircleIcon from '@mui/icons-material/Add'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFetchBooksByGroup } from 'services/books/getBooksByGroupService'
import { debounce } from 'lodash'
import {
  GetBooksByGroupFiltersByEnums,
  IBooksResponse,
} from '../../../services/books/bookServices.interface'
import { IGroup } from 'interfaces/groups'
import { TFunction } from 'interfaces/TFunction'

const useGroupBooks = ({
  group,
}: {
  group: IGroup
}): {
  t: TFunction
  books: IBooksResponse
  booksLoading: boolean
  refetchBooks: (params: { searchValue: string; groupId: string }) => void
  createButton: () => React.JSX.Element
  searchValue: string
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  groupId?: string
  loading: boolean
} => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')
  const groupId = group.id

  const openAddDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'addBookToGroup', true, {
      groupData: group,
    })
  }

  const createButton = (): React.JSX.Element => (
    <Button
      text={t('books_layout.add_book')}
      color="secondary"
      onClick={openAddDrawer}
      icon={<AddCircleIcon />}
      background="#06C68F"
    />
  )

  const { books, loading, refetch } = useFetchBooksByGroup({
    searchValue: '',
    filterBy: GetBooksByGroupFiltersByEnums.IN_GROUP,
    groupId,
  })
  const debouncedRefetch = useCallback(
    debounce(
      (value: string) =>
        refetch({
          searchValue: value,
          groupId,
          filterBy: GetBooksByGroupFiltersByEnums.IN_GROUP,
        }),
      500,
    ),
    [groupId],
  )
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newSearchValue = e.target.value
    setSearchValue(newSearchValue)
    debouncedRefetch(newSearchValue)
  }

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    refetch({
      searchValue,
      groupId,
    })
  }

  return {
    t,
    books,
    booksLoading: loading,
    refetchBooks: refetch,
    createButton,
    searchValue,
    onSearchSubmit,
    handleSearchChange,
    groupId,
    loading,
  }
}

export default useGroupBooks
