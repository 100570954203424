import { IconButton } from '@mui/material'
import { Drawer, Modal, TextInput } from 'components/common'
import {
  GridLayoutActions,
  GridToolbar,
} from 'components/common/Grid/styled-components'
import { ToolbarItem } from 'components/common/Toolbar'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'

import useCoachesLayout from 'hooks/coaches/useCoachesLayout'
import { FilterCoaches } from 'pages/coaches'
import {
  FlexComponent,
  LayoutWrapper,
  StyledPagination,
} from 'pages/coaches/styled-components'
import React, { ReactElement } from 'react'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import Grid from 'components/common/GridV2'
import _ from 'lodash'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import PaginationV2 from 'components/common/PaginationV2'
import { IGroup } from 'interfaces/groups'
import { IUserCompany } from 'interfaces/users'

const GroupTutors = ({ group }: { group: IGroup }): ReactElement => {
  const tutorIds =
    group?.courses?.reduce((acc: string[], course) => {
      if (course.coach) {
        acc.push(course.coach.coachId)
      }
      return acc
    }, []) || []

  const {
    t,
    filterDrawerOpened,
    setFilterDrawerOpened,
    filter,
    setFilterOptions,
    filterOptions,
    state,
    loading,
    users,
    selectedItem,
    currentPage,
    searchValue,
    handleSearchChange,
    isFiltered,
    config,
    selectAllItem,
    selectItem,
    handleChangeRowsPerPage,
    handlePaginationClick,
    pushNotificationModal,
    handleModalClose,
    perPage,
    messageData,
    setMessageData,
    handlePushNotificationSend,
  } = useCoachesLayout({
    tutorIds,
    suadaTutorsIncluded: true,
  })

  return (
    <LayoutWrapper>
      <Drawer opened={filterDrawerOpened} totalWidth="600px">
        <FilterCoaches
          role="COACH"
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          filter={filter}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          hasSearchByCompany
          manualCompanyId={(state?.currentUser?.companyId as IUserCompany)?.id}
          useCompanyId={
            !!(
              state?.currentUser?.companyId &&
              state.currentUser.companyId !== null
            )
          }
        />
      </Drawer>
      <GridToolbar>
        <GridLayoutActions></GridLayoutActions>
        <FlexComponent>
          <ToolbarItem>
            <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
              {isFiltered ? <RedFilterIcon color="#06C68F" /> : <FilterIcon />}
            </IconButton>
          </ToolbarItem>
          <form onSubmit={(e): void => e.preventDefault()}>
            <TextInput
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type="submit">
                  <SearchRoundedIcon />
                </IconButton>
              }
              $iconInStart
              name="search"
            />
          </form>
        </FlexComponent>
      </GridToolbar>
      <div
        style={{
          position: 'relative',
        }}
      >
        {users && (
          <Grid
            config={config}
            actionTilesLength={3}
            data={users.data}
            selected={_.size(selectedItem[currentPage])}
            selectAllItem={selectAllItem}
            selectItem={selectItem}
            selectedItems={selectedItem[currentPage] || []}
          />
        )}
        {users && users.data && (
          <StyledPagination>
            <PerPageDropdown
              value={perPage}
              onChange={(value): void =>
                handleChangeRowsPerPage({
                  target: { value: value.toString() } as HTMLInputElement,
                } as React.ChangeEvent<HTMLInputElement>)
              }
              options={[10, 20, 50]}
            />

            <PaginationV2
              currentPage={currentPage}
              totalPages={users?.totalPages || 0}
              handleChange={handlePaginationClick}
            />
          </StyledPagination>
        )}
      </div>
      <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
        <SendPushNotification
          messageData={messageData}
          setMessageData={setMessageData}
          onClose={handleModalClose}
          onSend={handlePushNotificationSend}
        />
      </Modal>
    </LayoutWrapper>
  )
}

export default GroupTutors
