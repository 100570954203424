import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as HorizontalSVG } from 'assets/horizontal-view.svg'
import { ReactComponent as GridSVG } from 'assets/grid-view.svg'
import { styled } from '@mui/material/styles'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useUserValue } from 'context/UserContext'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Button } from 'components/common/Button'
import { Input } from 'components/common/TextInput'
import Drawer from 'components/common/Drawer'
import { TabNavigation, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import FilterDrawer from './FilterDrawer/FilterDrawer'
import Player from 'components/common/VideoPlayer/Player'

import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import {
  ModalRef,
  ToolbarItemWrapper,
  ContentWrapper,
} from './styled-components'
import { Grid } from 'components/common/Cards/styled-components'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import { useFetchCourses, PER_PAGE } from 'hooks/course/useCourses'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { usePublishedCourse } from 'hooks/course/useEditCourse'
import { actionConfig, courseListActions } from './gridConfig'
import CourseList from './CourseList'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import PaginationV2 from 'components/common/PaginationV2'
import { SplashScreen } from 'components/common'
import MyLoader from 'loading/loading'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { IGroupsFilterDrawer } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import { FilterOptionsType } from './FilterDrawer/filterDrawer.interface'
import { checkIfFiltered } from 'utils/FilterIconHandler'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'

type IVideoData = {
  video: {
    links: { url: string }[]
  }
  subtitle: string
}

type IField = {
  id: string
}

const PREFIX = 'CourseLayout'

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
}

const stylesOut = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',

  '&.noBorder': {
    borderBottom: '1px solid transparent',
  },
}

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    color: '#000',
  },
}

export type filterData = {
  values: object
  filterOptions: object
}

export const initialValues: FilterOptionsType = {
  name: '',
  state: { value: 'all', label: 'All' },
  level: { value: 'all', label: 'All' },
  coaches: null,
  group: null,
  skills: null,
  video: { value: 'all', label: 'All' },
  studentRange: [0, 300],
}

const CourseLayout = (): React.JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isGridView, setIsGridView] = useState(true)

  const [state] = useUserValue()
  const [videoModal, setVideoModal] = useState({
    modal: false,
    data: '',
    subtitle: '',
  })

  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const { deleteCourse } = useDeleteCourse()
  const { fireSwal } = useSwal()
  const [searchText, setSearchText] = useState<string>('')
  const [filterData, setFilterData] = useState<IGroupsFilterDrawer>({
    values: initialValues,
    filterOptions: {},
  })
  const [filterQuery, setFilterQuery] = useState<IFilterQueryType>({})
  const [isFiltered, setIsFiltered] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(PER_PAGE)
  const [value, setValue] = useState(0)
  const [isSearchType, setIsSearchType] = useState<boolean>(false)

  const companyMode = state.selectedCompany?.id || state.currentUser.companyId
  const defaultFilter = companyMode
    ? {
        companyId: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: companyMode,
        },
        isBought: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: value === 0,
        },
      }
    : value === 0
    ? {
        companyId: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: null,
        },
      }
    : {
        companyId: {
          type: UpperCaseFilterableFieldType.NOT_EQUAL,
          value: null,
        },
        originalId: {
          type: UpperCaseFilterableFieldType.ARRAY_IN,
          value: [null],
        },
        isBought: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: false,
        },
      }

  const { courses, loading, refetch, error } = useFetchCourses(
    { ...defaultFilter, ...filterQuery, ...filterData.filterOptions },
    currentPage,
    perPage,
  )

  const { publishedCourse } = usePublishedCourse()

  const handleClick = (courseId: string): void => {
    navigate(`/courses/${courseId}`)
  }

  const handleEditClick = (field: IField): void =>
    DrawerEventEmitter.emit('openDrawer', 'editCourse', true, {
      id: field.id,
    })

  const handleNewCourse = (): void => {
    const companyId =
      state.selectedCompany?.id || state.currentUser.companyId || null
    const isBought = companyId
      ? {
          type: UpperCaseFilterableFieldType.EXACT,
          value: value === 0,
        }
      : {}
    DrawerEventEmitter.emit('openDrawer', 'addCourses', true, {
      data: {
        hasSearchByCompany: false,
        companyId,
        onSuccess: () => {
          refetch({
            filter: {
              companyId: {
                type: UpperCaseFilterableFieldType.EXACT,
                value: companyId,
              },
              isBought,
            },
            currentPage: 1,
            perPage,
          })
        },
        pagination: {
          currentPage,
          perPage,
        },
      },
    })
  }

  const handleDeleteCourse = (ids: string[]): void => {
    if (ids.length <= 0) return

    const params = {
      title: `${
        ids.length > 1
          ? t('popups.delete_course_many')
          : t('popups.delete_course_single')
      }`,
      onConfirm: (): void => {
        deleteCourse(ids, refetch)
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  useEffect(() => {
    setIsFiltered(
      checkIfFiltered(
        (filterData.filterOptions as IFilterQueryType) ||
          ({} as IGroupFilterDrawerOptions),
      ),
    )
  }, [filterData.filterOptions])

  const filterDrawer = (): void => {
    setFilterIsOpen(!filterIsOpen)
  }

  const resetFilter = (): void => {
    setFilterQuery({})
    setFilterData({} as filterData)
    setFilterIsOpen(false)
    setSearchText('')
  }

  //reset to tab 0, when we don't have companyId
  useEffect(() => {
    if (!state.currentUser.companyId && !state.selectedCompany?.id) {
      setValue(0)
    }
  }, [state.currentUser.companyId, state.selectedCompany?.id])

  const debouncedRefetch = useRef(
    debounce((newSearchText: string) => {
      const formValue = {
        name: {
          type: UpperCaseFilterableFieldType.MATCH,
          value: newSearchText,
        },
      }
      setFilterQuery((prevFilterQuery) => ({
        ...prevFilterQuery,
        ...formValue,
      }))
      refetch({
        currentPage: 1,
        perPage,
        filter: {
          ...defaultFilter,
          ...formValue,
        },
      })
      setIsSearchType(newSearchText !== '')
    }, 500),
  ).current

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearchText = e.target.value
    setSearchText(newSearchText)
    debouncedRefetch(newSearchText)
    setIsSearchType(true)
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    debouncedRefetch(searchText)
    setIsSearchType(true)
  }

  useEffect(() => {
    return () => {
      debouncedRefetch.cancel()
    }
  }, [debouncedRefetch])

  const handlePublishedCourse = (courseId: string[], action: string): void => {
    let title = ''
    let confirmText = ''

    if (action === 'unpublished') {
      title = `<span style="color:red">${t('popups.unpublish_course')}</span>`
      confirmText = t('popups.confirm_unpublish')
    } else {
      title = `<span style="color: green">${t('popups.publish_course')}</span>`
      confirmText = t('popups.confirm_publish')
    }

    fireSwal({
      title,
      onConfirm: () => publishedCourse(courseId, action),
      confirmText,
    })
    //
  }

  const handleVideoModal = (data: IVideoData): void => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      data: !videoModal.modal ? data.video.links[0].url : '',
      subtitle: !videoModal.modal ? data.subtitle : '',
    })
  }

  const handlePaginationClick = (
    _: React.ChangeEvent<unknown>,
    value: number,
  ): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  const handleTabChange = (
    event: React.ChangeEvent<object> | undefined,
    newValue: number,
  ): void => {
    setFilterData({} as filterData)
    setValue(newValue)
  }

  const actionHandler = (
    event: string,
    field: IField | string | IVideoData,
    action: string = '',
  ): void | (() => void) => {
    switch (event) {
      case 'viewCourse':
        return handleClick(field as string)
      case 'deleteCourse':
        return handleDeleteCourse([field as string])
      case 'publishedUnPublished':
        return handlePublishedCourse([field as string], action)
      case 'editCourse':
        return handleEditClick(field as IField)
      case 'playIntro':
        return handleVideoModal(field as IVideoData)
      default:
        return () => console.log('')
    }
  }

  const actions = actionConfig(actionHandler, state.userPermission, t)

  const listActions = courseListActions(
    actionHandler,
    t,
    state.userPermission,
    value,
  )
  const { currentCompany, currentUser } = state

  const createButton = (): React.JSX.Element => (
    <>
      {state.userPermission.createCourse && (
        <Button
          text={`${
            state.selectedCompany?.id
              ? t('courses_layout.create_course')
              : t('courses_layout.create_suada_course')
          }`}
          color="secondary"
          icon={<AddCircleRoundedIcon />}
          onClick={handleNewCourse}
          isDisabled={
            !!currentUser.companyId && !currentCompany.platformService
          }
          background="#06C68F"
        />
      )}
    </>
  )

  const breadCrumbData = [
    {
      label: 'Courses',
      icon: <Learning />,
    },
  ]

  return (
    <Root>
      <ToolbarItem>
        <Breadcrumb data={breadCrumbData} />
      </ToolbarItem>
      <Toolbar styles={toolbarStyle}>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {state.userPermission.createCourse && (
              <Button
                text={`${
                  state.selectedCompany?.id
                    ? t('courses_layout.create_course')
                    : t('courses_layout.create_suada_course')
                }`}
                color="secondary"
                icon={<AddCircleRoundedIcon />}
                onClick={handleNewCourse}
                isDisabled={
                  !!currentUser.companyId && !currentCompany.platformService
                }
                background="#06C68F"
              />
            )}
          </div>

          <div className="wrapper">
            <ToolbarItem>
              {isGridView ? (
                <IconButton onClick={(): void => setIsGridView(false)}>
                  <GridSVG />
                </IconButton>
              ) : (
                <IconButton onClick={(): void => setIsGridView(true)}>
                  <HorizontalSVG />
                </IconButton>
              )}
            </ToolbarItem>

            <ToolbarItem $noGap>
              <IconButton onClick={filterDrawer}>
                {isFiltered ? <RedFilterIcon /> : <FilterIcon />}
              </IconButton>
              {isFiltered && (
                <IconButton onClick={resetFilter}>
                  <FilterAltOffIcon />
                </IconButton>
              )}
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>
      <div>
        <TabNavigation transparent>
          <AppBar position="static" style={styles.bar}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab
                label={
                  state.currentUser.companyId || state.selectedCompany?.id
                    ? t('general.purchased_courses')
                    : t('general.suada_courses')
                }
                {...activeTabClassName(0)}
                style={styles.btn}
              />
              {(state.currentUser.companyId || state.selectedCompany?.id) && (
                <Tab
                  label={t('general.created_courses')}
                  {...activeTabClassName(1)}
                  style={styles.btn}
                />
              )}
            </Tabs>
          </AppBar>
        </TabNavigation>
      </div>
      <ContentWrapper $isGrid={isGridView}>
        {error && error}
        {loading ? (
          <MyLoader />
        ) : (
          <>
            {!loading &&
            courses &&
            companyMode &&
            searchText === '' &&
            !isSearchType &&
            !isFiltered &&
            courses?.data.length === 0 ? (
              value === 0 ? (
                <SplashScreen
                  subTitle={t('courses_layout.no_purchased_courses')}
                />
              ) : (
                <SplashScreen
                  title={t('courses_layout.no_courses')}
                  subTitle={t('courses_layout.create_courses')}
                  createButton={createButton}
                />
              )
            ) : (
              <>
                {courses.data.length === 0 &&
                (searchText !== '' || isFiltered) ? (
                  <SplashScreen
                    title={t('general.no_results_found')}
                    subTitle={t('general.Try_adjusting_your_search_or_filter')}
                    textMarginTop="40px"
                    isSearchOrFilter={true}
                  />
                ) : (
                  <>
                    {courses.data.length > 0 ? (
                      <>
                        <Grid
                          className={'card'}
                          style={{ position: 'relative' }}
                        >
                          <CourseList
                            isGridView={isGridView}
                            companyMode={!!companyMode}
                            activeTab={value}
                            state={state}
                            actionHandler={actionHandler}
                            data={courses?.data}
                            gridStyle={'card'}
                            withTooltips={false}
                            actions={actions}
                            courseListActions={listActions}
                          />
                        </Grid>
                        <div
                          style={{
                            marginTop: 20,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <PaginationV2
                            currentPage={courses ? courses.currentPage : 1}
                            totalPages={courses ? courses.totalPages : 0}
                            handleChange={handlePaginationClick}
                          />
                        </div>
                      </>
                    ) : (
                      <MyLoader />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </ContentWrapper>
      <Drawer
        opened={filterIsOpen}
        toggleDrawer={(): void => undefined}
        totalWidth="700px"
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          refetchCourse={refetch}
          resetFilter={resetFilter}
          onClose={(): void => setFilterIsOpen(false)}
          tabValue={value}
          isCooursePrivacyHidden={true}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={stylesOut.modal}
        open={videoModal.modal}
        onClose={(): void => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <ModalRef>
          <Player url={videoModal.data} subtitle={videoModal.subtitle} />
        </ModalRef>
      </Modal>
    </Root>
  )
}

export default CourseLayout
