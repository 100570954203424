import React, { useState, useRef, MouseEvent, ReactElement } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  Wrapper,
  ItemWrapper,
  NotificationContainer,
} from './styled-components'

import ProfileDropdown from 'components/ProfileDropdown/ProfileDropdown'
import { NotificationBadge, NotificationMenu } from 'pages/notifications'
import useOutsideClick from 'helpers/useOutsideClick'
import Popover from '@mui/material/Popover'

import { GET_NOTIFICATION_COUNT } from 'gql/notifications/notifications.query'
import { IUser } from 'interfaces/users'
import CompanySwitcher from './companySwitcher/CompanySwitcher'

interface IHeaderProps {
  currentUser: IUser
  open: boolean
}

const Header = ({ currentUser }: IHeaderProps): ReactElement => {
  const ref = useRef(null)
  const [notificationMenu, setNotificationMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  useOutsideClick(ref, () => {
    if (notificationMenu) setNotificationMenu(false)
  })

  const handleClick = (
    event: MouseEvent<HTMLSpanElement> | undefined,
  ): void => {
    if (event && event.currentTarget)
      setAnchorEl(event.currentTarget as Element)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const [fetchNotificationCount, { data }] = useLazyQuery(
    GET_NOTIFICATION_COUNT,
  )

  const numberOfNewNotifications =
    (data && data.getNotificationCount.numberOfNotifications) || 0

  const menuOpen = Boolean(anchorEl)
  const id = notificationMenu ? 'simple-popover' : undefined

  return (
    <Wrapper>
      <ItemWrapper>
        <NotificationContainer>
          <NotificationBadge
            open={handleClick}
            numberOfNewNotifications={numberOfNewNotifications}
          />

          <Popover
            id={id}
            open={menuOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{
              marginTop: '10px',
              marginLeft: '14px',
            }}
          >
            <NotificationMenu
              notificationBadgeRefetch={fetchNotificationCount}
              onClose={handleClose}
            />
          </Popover>
        </NotificationContainer>
        <CompanySwitcher />
        {currentUser && <ProfileDropdown user={currentUser} />}
      </ItemWrapper>
    </Wrapper>
  )
}

export default Header
